import React, { useRef, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
// import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/hero/TwoColumnWithInput.js";
import Features from "../components/features/ThreeColWithSideImage.js";
import MainFeature from "../components/features/TwoColWithButton.js";
// import MainFeature2 from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "../components/features/TwoColWithSteps.js";
import Pricing from "../components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "../components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../components/faqs/SingleCol.js";
// import GetStarted from "../components/cta/GetStarted";
import Footer from "../components/footers/MiniCenteredFooter.js";
// import heroScreenshotImageSrc from "../images/hero-screenshot-1.png";
import professionalImageSrc from "../images/professional.png";
import getStartedImageSrc from "../images/getStarted.png";
// import macHeroScreenshotImageSrc from "../images/hero-screenshot-2.png";
import ContactUsForm from "../components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "../components/cards/ThreeColContactDetails.js";
// import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as WhatsAppIcon } from "../images/whatsapp-icon.svg";

// const Container = tw.div`relative bg-white bg-opacity-50 rounded-xl p-2`;

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.a`text-sm mt-0 block text-gray-500`;
const CallView = tw.div`flex flex-row justify-center items-center mb-2 animate-bounce`;
const WAButton = tw.button`relative w-8 h-8 cursor-pointer overflow-hidden p-1 ml-4`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const homeRef = useRef(null);
  const contactRef = useRef(null);
  const servicesRef = useRef(null);
  const pricingRef = useRef(null);
  const getStartedRef = useRef(null);
  const faqRef = useRef(null);
  const reviewsRef = useRef(null);

  const [planSelected, setPlanSelected] = useState("");

  const onScroll = (route) => {
    switch (route) {
      case "Home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Services":
        servicesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Pricing":
        pricingRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "GetStarted":
        getStartedRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "FAQs":
        faqRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Reviews":
        reviewsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div ref={homeRef} />
      <Hero roundedHeaderButton={true} onClick={onScroll} />
      <div ref={servicesRef} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
        description="Unlock the Power of Our Service. Stand out online with our expertise."
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={professionalImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <div ref={pricingRef} />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        description={
          "Choose our Basic Package to enjoy a professionally designed landing page, along with a domain name of your choice (subject to availability) for a strong online presence. Your visitors will experience the benefits of a free SSL certificate, ensuring secure connections and trust. With preminus plan, enhance user engagement with the added convenience of a booking appointment option."
        }
        onPlanClicked={(plan) => {
          setPlanSelected(`I'm interested in ${plan?.name}`);
          onScroll("Contact");
        }}
      />
      <div ref={getStartedRef} />
      <FeatureWithSteps
        subheading={<Subheading>Get Started</Subheading>}
        heading={
          <>
            Seamless Onboarding in Three Simple{" "}
            <HighlightedText>Steps.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={getStartedImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      <div ref={reviewsRef} />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description="Discover What Our Clients Say: Read testimonials from satisfied customers and learn about their experiences with our services."
        testimonials={[
          {
            stars: 4,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "",
            quote:
              "The Premium package with the 'Book Appointment' feature was a game-changer for my business. It made scheduling appointments a breeze for my clients. The only reason I didn't give it 5 stars is that I wish there were more customization options for the design.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "",
            quote:
              "I can't believe how effortless it was to get my landing page up and running with the Basic package. I just provided my content and images, and your team handled everything else. It saved me so much time and headache!",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      />
      <div ref={faqRef} />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        description="Find solutions to common queries in our FAQ section, designed to provide you with quick and comprehensive answers."
        faqs={[
          {
            question:
              "What is the difference between the Basic and Premium packages?",
            answer:
              'The Basic package includes essential features such as a professionally designed landing page, domain name registration, a free SSL certificate, and technical support. It does not include the "Book Appointment" feature. In contrast, the Premium package includes all the Basic features and adds the "Book Appointment" option, allowing your visitors to easily schedule appointments on your landing page.',
          },
          {
            question:
              "Can I customize the content and design of my landing page?",
            answer:
              "Certainly. Customization of your landing page can be efficiently managed by our team of experts. You will need to provide us with the specific content and images that fit our existing template, and we will meticulously replace and adjust them to align with your unique vision and requirements.",
          },
          {
            question:
              "Are there any hidden costs or technical requirements not mentioned in the packages?",
            answer:
              "No, there are no hidden costs or technical requirements beyond what's mentioned in the packages. The pricing and features provided are transparent, and our technical support team is available to assist you with any questions or issues related to your landing page and domain. Please note that code for custom development is not included.",
          },
        ]}
      />
      <div ref={contactRef} />
      <ContactUsForm message={planSelected} />
      <ContactDetails
        description=""
        cards={[
          {
            title: "Mumbai",
            description: (
              <>
                <Address>
                  <AddressLine>D-2, Bhadran Nagar,</AddressLine>
                  <AddressLine>Malad West, Mumbai 400064</AddressLine>
                </Address>
                <Email></Email>
                {/* <CallView>
                  <Phone href="tel:959-486-0746">+91 (959) 486-0746</Phone>
                  <WAButton onClick={() => window.open('https://wa.me/9594860746', '_blank')}><WhatsAppIcon /></WAButton>
                </CallView> */}
                <CallView>
                  <Phone href="tel:986-777-9087">+91 (986) 777-9087</Phone>
                  <WAButton
                    onClick={() =>
                      window.open("https://wa.me/9867779087", "_blank")
                    }
                  >
                    <WhatsAppIcon />
                  </WAButton>
                </CallView>
              </>
            ),
          },
          // {
          //   title: "Map",
          //   description: (
          //     <>
          //       {'Map location coming soon'}
          //     </>
          //   )
          // }
        ]}
      />
      <Footer onClick={onScroll} />
    </div>
  );
};
