import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

// import {Container as ContainerBase } from "../../components/misc/Layouts.js"
// import logo from "../../images/logo.svg";
// import onlinebadhologo from "../../images/onlinebadho.png";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";

const Container = tw.div`relative bg-gray-900 text-gray-100 -m-8`;
// const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -m-8` // -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
// const LogoImg = tw.img`w-32`;
// const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4 cursor-pointer`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default ({ onClick }) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            {/* <LogoImg src={onlinebadhologo} /> */}
            {/* <LogoText><span tw="text-primary-500 mr-1">Online</span>Badho</LogoText> */}
          </LogoContainer>
          <LinksContainer>
            <Link onClick={() => onClick("Home")}>Home</Link>
            <Link onClick={() => onClick("Services")}>Services</Link>
            <Link onClick={() => onClick("Pricing")}>Pricing</Link>
            <Link onClick={() => onClick("GetStarted")}>Get Started</Link>
            <Link onClick={() => onClick("FAQs")}>FAQs</Link>
            <Link onClick={() => onClick("Reviews")}>Reviews</Link>
            <Link onClick={() => onClick("Contact")}>Contact Us</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink
              target="_blank"
              href="https://instagram.com/onlinebadho"
            >
              <InstagramIcon />
            </SocialLink>
            {/* <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, Online Badho Inc. All
            Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
      <span tw="text-[4px]">
        Image by{" "}
        <a href="https://www.freepik.com/free-psd/modern-landing-page-mockup_4048766.htm#query=professional%20Cartoon%20Website%20Images&position=12&from_view=search&track=ais">
          Freepik
        </a>
      </span>
    </Container>
  );
};
