import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line

const ModalContainer = tw.div`
    fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none shadow-md
`;

const ModalContent = tw.div`
    bg-white border border-gray-300 rounded-lg p-6 mx-auto w-1/2 transform translate-y-0 ease-in-out duration-300
`;

const SuccessModal = ({ open, onClose, success }) => {
  if (!open) return null;

  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <p tw="text-xl font-semibold text-center">{success ? `Thank You for Submitting!` : 'Failed'}</p>
        <p tw="text-gray-700 text-center mt-3">
            {success ? `Your form has been successfully submitted.` : 'Failed to submit the data, contact via phone number'}
        </p>
        <button
          tw="block mx-auto mt-6 bg-primary-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none"
          onClick={onClose}
        >
          Close
        </button>
      </ModalContent>
    </ModalContainer>
  );
};

export default SuccessModal;
