// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import GlobalStyles from './styles/GlobalStyles';
import AnimationRevealPage from "./helpers/AnimationRevealPage.js"
import ProductLandingPage from './pages/ProductLandingPage';
import { css } from "styled-components/macro"; //eslint-disable-line
import { isMobile } from './components/misc/Layouts';

// const FallbackComponent = () => {
//   return <div>Loading...</div>;
// };

// const BaseComponent = React.lazy(() => import('./pages/ProductLandingPage'))
function App() {
  return (
    <div className={isMobile ? '' : 'bg'}>
      <GlobalStyles />
      {/* <Suspense fallback={<FallbackComponent />}>
        <BaseComponent/>
      </Suspense> */}
      <AnimationRevealPage>
        <ProductLandingPage />
      </AnimationRevealPage>
    </div>
  );
}

export default App;
