import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";
import SuccessModal from "../../helpers/Modal.js";
import { API_SUBMIT_FORM, BASE_URL } from "../../config/constants.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-2 rounded-lg px-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 opacity-75`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const LoaderContainer = tw.div`flex items-center justify-center mt-12`
const Loader = tw.div`border-t-4 border-primary-500 border-solid rounded-full w-5 h-5 animate-spin`
const Error = tw.div`flex text-red-500 mt-1`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Reach Out to Us: Have questions or inquiries? Our team is here to assist you.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  message = ''
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState({open: false, success: false});
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      message: message,
    });
  }, [message])

  const handleSubmit = () => {
    if(!validateForm()) return
    setLoading(true)
    const params = new URLSearchParams(formData);
    // params.append('name', 'Chetan Parmar');
    // params.append('email', 'chetan@gmail.com');
    // params.append('contact', '1234567890');
    // params.append('enquiry', 'This is test mail from react');
    const urlWithParams = `${BASE_URL+API_SUBMIT_FORM}?${params.toString()}`;
    console.log('urlwith', urlWithParams)
    try {
      fetch(urlWithParams).then((res) => {
          const errorMessage = res.headers.get('Error-Message');
          if (errorMessage) { console.error('Server Error:', errorMessage, res) }
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(() => {
          setLoading(false)
          setIsSuccessModalOpen({open: true, success: true})
          setFormData({name: '', email: '', phone: '', message: ''})
        }).catch((e) => {
          setLoading(false)
          setIsSuccessModalOpen({open: true, success: false})
        })
    } catch {
      setIsSuccessModalOpen({open: true, success: false})
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen({open: false, success: false});
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Name should not be empty
    if (!formData.name.trim()) {
      newErrors.name = 'Please enter your full name.';
      valid = false;
    } else {
      newErrors.name = '';
    }

    // Email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
      valid = false;
    } else {
      newErrors.email = '';
    }

    // Phone number validation using a regular expression
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number.';
      valid = false;
    } else {
      newErrors.phone = '';
    }

    // Enquiry should not be empty
    if (!formData.message.trim()) {
      newErrors.message = 'Please enter your enquiry.';
      valid = false;
    } else {
      newErrors.message = '';
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form>
              <Input value={formData.name} onChange={handleChange} type="text" name="name" placeholder="Full Name" disabled={loading} />
              <Error>{errors.name}</Error>
              <Input value={formData.email} onChange={handleChange} type="email" name="email" placeholder="Your Email Address" disabled={loading} />
              <Error>{errors.email}</Error>
              <Input value={formData.phone} onChange={handleChange} type="phone" name="phone" placeholder="Contact Number" disabled={loading} maxLength={10} />
              <Error>{errors.phone}</Error>
              <Textarea value={formData.message} onChange={handleChange} name="message" placeholder="Your Enquiry Here" disabled={loading} />
              <Error>{errors.message}</Error>
              {/* <PrimaryLink css={tw`rounded-full mt-8`} >Send</PrimaryLink> */}
              {!loading && <SubmitButton onClick={handleSubmit}>
                {submitButtonText}
              </SubmitButton>}
              {loading && <LoaderContainer><Loader /></LoaderContainer>}
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <SuccessModal {...isSuccessModalOpen} onClose={closeModal} />
    </Container>
  );
};
